const apiUrl = '/wp-json/wp/v2/';
const { rest_nonce: nonce } = window.themeSettings;

export async function submitForm(email) {
  const requestUrl = '/wp-json/api/v1/newsletter';
  const response = await fetch(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'X-WP-Nonce': nonce,
    },
    body: JSON.stringify({ email }),
  });
  const result = await response.json();
  return result;
}

export async function fetchPosts({
  offset,
  perPage,
  categoryId = null,
  tagId = null,
  profileSlug = null,
}) {
  let requestUrl = `${apiUrl}posts?per_page=${perPage}&offset=${offset}`;
  if (categoryId) {
    requestUrl += `&categories=${categoryId}`;
  }
  if (tagId) {
    requestUrl += `&tags=${tagId}`;
  }
  if (profileSlug) {
    requestUrl += `&profile=${profileSlug}`;
  }
  const response = await fetch(requestUrl);
  const totalPosts = response.headers.get('X-WP-Total');
  const posts = await response.json();
  return {
    totalPosts,
    posts,
  };
}

export async function fetchSearchResults({
  searchText,
  // perPage = 10,
  page,
  subtype = null,
}) {
  let requestUrl = `/wp-json/api/v1/search?search=${searchText}&page=${page}`;
  if (subtype) {
    requestUrl += `&subtype=${subtype}`;
  }
  const response = await fetch(requestUrl);
  // const totalPosts = response.headers.get('X-WP-Total');
  const totalPages = response.headers.get('X-WP-TotalPages');
  const posts = await response.json();
  return {
    // totalPosts,
    totalPages,
    posts,
  };
}

export async function fetchDocuments({ category, perPage = 10, page }) {
  const requestUrl = `${apiUrl}document?document_category=${category}&per_page=${perPage}&page=${page}`;
  const response = await fetch(requestUrl);
  const totalPosts = response.headers.get('X-WP-Total');
  const totalPages = response.headers.get('X-WP-TotalPages');
  let posts = await response.json();
  posts = posts.map((item) => item.data);
  return {
    totalPosts,
    totalPages,
    posts,
  };
}
