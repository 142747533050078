import { submitForm } from '../utils/service';

export default () => {
  const forms = document.querySelectorAll('.newsletter__form');

  forms.forEach((form) => {
    const submitButton = form.querySelector('.newsletter__submit');
    const message = form.querySelector('.newsletter__message');
    const emailLabel = form.querySelector('.newsletter__email-label');
    const emailInput = form.querySelector('.newsletter__email');
    const clearButton = form.querySelector('.newsletter__clear');

    if (emailInput && emailLabel) {
      emailInput.addEventListener('invalid', (e) => {
        const input = e.currentTarget;
        emailLabel.classList.add('invalid');
        if (input.value) emailLabel.classList.add('invalid--value');
      });
    }

    if (clearButton && emailInput && emailLabel) {
      clearButton.addEventListener('click', () => {
        emailInput.value = '';
        emailLabel.classList.remove('invalid--value');
      });
    }

    if (form) {
      form.addEventListener('submit', async (e) => {
        e.preventDefault();
        emailLabel.classList.remove('invalid', 'invalid--value');
        form.classList.add('newsletter__form--submitted');
        submitButton.disabled = true;
        const response = await submitForm(form.email.value);
        if (response?.status === 'subscribed') {
          window.formSubmited = true;
          message.textContent = 'Successfully subscribed';
        } else {
          message.classList.add('newsletter__message--error');
          message.textContent = response?.message || response?.title;
        }
        window.setTimeout(() => {
          submitButton.disabled = false;
          form.classList.remove('newsletter__form--submitted');
          message.textContent = '';
          message.classList.remove('newsletter__message--error');
          form.reset();
        }, 3000);
      });
    }
  });
};
