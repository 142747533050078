import Swiper, { Navigation } from 'swiper';

export default () => {
  const speakersBlockSliders = document.querySelectorAll(
    '.speakers-block__slider'
  );

  if (speakersBlockSliders.length) {
    speakersBlockSliders.forEach((slider) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const swiper = new Swiper(slider, {
        modules: [Navigation],
        navigation: {
          nextEl: slider.querySelector('.speakers-block__next'),
          prevEl: slider.querySelector('.speakers-block__prev'),
        },
        slidesPerView: 1,
        spaceBetween: 30,
        watchSlidesProgress: true,
        breakpoints: {
          480: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          767: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
      });
    });
  }
};
