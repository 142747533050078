import copy from 'copy-to-clipboard';

export default () => {
  const copyButton = document.querySelector('.copy-button');

  if (copyButton) {
    copyButton.addEventListener('click', (e) => {
      const target = e.currentTarget;
      target.disabled = true;
      copy(window.location.href);
      target.classList.add('copy-button--copied');
      window.setTimeout(() => {
        target.classList.remove('copy-button--copied');
        target.disabled = false;
      }, 2000);
    });
  }
};
