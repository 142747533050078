import { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import parse from 'html-react-parser';
import queryString from 'query-string';

import SpriteIcon from '../sprite-icon/sprite-icon';
import Loader from '../loader/loader';
import { fetchDocuments } from '../../../utils/service';

// eslint-disable-next-line no-underscore-dangle
const initialState = window.__INITIAL_STATE__;

type Document = {
  id: number;
  date: string;
  excerpt: string;
  title: string;
  url: string;
};

type DocumentsCategory = {
  id: number;
  name: string;
  slug: string;
  documents: {
    per_page: number;
    total: number;
    posts: Document[];
  };
};

function DocumentsPage(): JSX.Element {
  const { categories = [] } = initialState;

  const query = queryString.parse(window.location.search);
  const categoryFromQuery =
    query.category &&
    categories.find((item: DocumentsCategory) => item.slug === query.category);

  const perPage =
    categoryFromQuery?.documents?.per_page || categories[0].documents.per_page;
  const initialPosts =
    categoryFromQuery?.documents?.posts || categories[0].documents.posts || [];
  const initialTotalPosts =
    categoryFromQuery?.documents?.total || categories[0].documents.total;
  const initialTotalPages = Math.ceil(initialTotalPosts / perPage);

  const [isLoading, setIsLoading] = useState(false);
  const [activeCategory, setActiveCategory] = useState<DocumentsCategory>(
    categoryFromQuery || categories[0]
  );
  const [posts, setPosts] = useState<Document[]>(initialPosts);
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPosts, setTotalPosts] = useState(initialTotalPosts);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(initialTotalPages);

  useEffect(() => {
    if (categories.length === 1) {
      document
        .querySelector('.documents__header .wrapper')
        ?.classList.add('wrapper--narrow');
    }
  }, [categories]);

  useEffect(() => {
    window.history.replaceState(null, '', `?category=${activeCategory.slug}`);
  }, [activeCategory]);

  const fetchData = useCallback(
    async (page: number, category: DocumentsCategory) => {
      setIsLoading(true);
      const {
        posts: fetchedPosts,
        totalPages: totalPagesNum,
        totalPosts: totalPostsNum,
      } = await fetchDocuments({
        category: category.id,
        perPage,
        page,
      });
      setCurrentPage(page);
      if (page === 1) {
        setPosts(fetchedPosts);
      } else {
        setPosts([...posts, ...fetchedPosts]);
      }
      setTotalPages(totalPagesNum);
      setTotalPosts(totalPostsNum);
      setIsLoading(false);
    },
    [posts, perPage]
  );

  const handleCategories = async (category: DocumentsCategory) => {
    if (!isLoading) {
      fetchData(1, category);
      setActiveCategory(category);
      window.history.replaceState(null, '', `?category=${category.slug}`);
    }
  };

  const handleLoadMore = () => {
    if (!isLoading) {
      fetchData(currentPage + 1, activeCategory);
    }
  };

  return (
    <section className="documents">
      <div
        className={classnames('documents__wrapper wrapper', {
          'wrapper--narrow': categories.length === 1,
        })}
      >
        <div className="documents__columns">
          {categories.length > 1 && (
            <ul className="documents__categories">
              {categories.map((category: DocumentsCategory) => (
                <li className="documents__category" key={category.id}>
                  <button
                    className={classnames('documents__category-button', {
                      'documents__category-button--active':
                        category.id === activeCategory.id,
                    })}
                    type="button"
                    onClick={() => handleCategories(category)}
                  >
                    {category.name}
                  </button>
                </li>
              ))}
            </ul>
          )}
          <div className="documents__main">
            {posts.length === 0 && isLoading && <Loader />}
            <ul className="documents__list">
              {posts.map((post: Document) => (
                <li className="documents__item" key={post.id}>
                  <article className="documents__document document">
                    <h2 className="document__title">
                      <a href={post.url} className="document__link">
                        <SpriteIcon
                          className="document__icon"
                          icon="document"
                        />
                        {!!post.title && parse(post.title)}
                      </a>
                    </h2>
                    <time className="document__date">{post.date}</time>
                    <div className="document__excerpt">{post.excerpt}</div>
                  </article>
                </li>
              ))}
            </ul>
            {currentPage < totalPages && posts.length > 0 && (
              <div className="documents__loadmore loadmore">
                <button
                  className="loadmore__button button button--secondary"
                  type="button"
                  onClick={handleLoadMore}
                  disabled={isLoading}
                >
                  Vis mer
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default DocumentsPage;
