import classnames from 'classnames';

type SpriteIconProps = {
  icon: string;
  className?: string;
};

function SpriteIcon(props: SpriteIconProps): JSX.Element {
  const { icon, className = '' } = props;
  return (
    <svg
      className={classnames('sprite-icon', `sprite-icon-${icon}`, className)}
    >
      <use xlinkHref={`#${icon}`} />
    </svg>
  );
}

export default SpriteIcon;
