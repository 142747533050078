export default () => {
  if (window.themeSettings.newsletter_popup.show === true) {
    const body = document.querySelector('body');
    const container = document.querySelector('.newsletter-popup');
    const closeButton = container.querySelector('.newsletter-popup__close');
    const form = container.querySelector('.newsletter__form');
    const localStorageKey = 'closeNewsletterPopup';

    if (!localStorage.getItem(localStorageKey)) {
      window.setTimeout(() => {
        container.classList.remove('newsletter-popup--is-hidden');
        body.classList.add('no-scroll');
      }, window.themeSettings.newsletter_popup.delay * 1000);
    }

    closeButton.addEventListener('click', () => {
      localStorage.setItem(localStorageKey, 'true');
      container.classList.add('newsletter-popup--is-hidden');
      body.classList.remove('no-scroll');
    });

    form.addEventListener('submit', () => {
      function isFormSubmited() {
        if (typeof window.formSubmited !== 'undefined') {
          window.setTimeout(() => {
            localStorage.setItem(localStorageKey, 'true');
            container.classList.add('newsletter-popup--is-hidden');
            body.classList.remove('no-scroll');
          }, 2000);
        } else {
          setTimeout(isFormSubmited, 500);
        }
      }

      isFormSubmited();
    });
  }
};
