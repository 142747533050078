export default () => {
  const buttons = document.querySelectorAll('.expandable-block__button');

  if (buttons.length) {
    buttons.forEach((button) => {
      button.addEventListener('click', (e) => {
        const target = e.currentTarget;
        const block = target.closest('.expandable-block');
        block.classList.add('expandable-block--open');
      });
    });
  }
};
