import { useState, useCallback } from 'react';
import parse from 'html-react-parser';
import queryString from 'query-string';
import { fetchSearchResults } from '../../../utils/service';

import SearchFilters from '../search-filters/search-filters';
import SpriteIcon from '../sprite-icon/sprite-icon';
import SearchForm from '../search-form/search-form';
import Loader from '../loader/loader';

import { SearchFilter } from '../../types/search';

// eslint-disable-next-line no-underscore-dangle
const initialState = window.__INITIAL_STATE__;

const filters: SearchFilter[] = [
  { id: 1, name: 'Artikler', subtype: 'post' },
  { id: 2, name: 'Dokumenter', subtype: 'document' },
  { id: 3, name: 'Sider', subtype: 'page' },
  { id: 4, name: 'Kategorier/Stikkord', subtype: 'taxonomy' },
];

function Search(): JSX.Element {
  const query = queryString.parse(window.location.search);
  const {
    posts: initialPosts = [],
    found_posts: initialTotalPosts,
    pages: initialTotalPages,
  } = initialState;

  const [activeFilter, setActiveFilter] = useState<SearchFilter | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState(initialPosts);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(initialTotalPages);

  const fetchData = useCallback(
    async (page: number, filter?: SearchFilter) => {
      setIsLoading(true);
      const { posts: fetchedPosts, totalPages: totalPagesNum } =
        await fetchSearchResults({
          searchText: query.s,
          page,
          subtype: filter?.subtype,
        });
      setCurrentPage(page);
      if (page === 1) {
        setPosts(fetchedPosts);
      } else {
        setPosts([...posts, ...fetchedPosts]);
      }
      setTotalPages(totalPagesNum);
      setIsLoading(false);
    },
    [posts, query.s]
  );

  const handleFilters = async (filter: SearchFilter) => {
    if (!isLoading) {
      setActiveFilter(filter);
      fetchData(1, filter);
    }
  };

  const handleLoadMore = () => {
    if (!isLoading) {
      fetchData(currentPage + 1, activeFilter);
    }
  };

  return (
    <div className="search__wrapper wrapper">
      <h1 className="search__title">Søkeresultat</h1>
      <SearchForm searchText={query.s} />
      <div className="search__info">
        «{query.s}» ga {initialTotalPosts} resultater
      </div>
      <div className="search__columns">
        <SearchFilters
          className="search__filters"
          filters={filters}
          activeFilter={activeFilter}
          onFilterClick={handleFilters}
        />
        <div className="search__main">
          {posts.length === 0 && isLoading && <Loader />}
          <ul className="search__results">
            {posts.map((post: any) => (
              <li className="search__result" key={post.url}>
                <a className="search__link" href={post.url}>
                  {post.subtype === 'post' && (
                    <SpriteIcon
                      className="search__icon search__icon--post"
                      icon="article"
                    />
                  )}
                  {post.subtype === 'page' && (
                    <SpriteIcon
                      className="search__icon search__icon--page"
                      icon="arrow-green"
                    />
                  )}
                  {post.subtype === 'document' && (
                    <SpriteIcon
                      className="search__icon search__icon--document"
                      icon="document"
                    />
                  )}
                  {post.subtype === 'taxonomy' && (
                    <SpriteIcon
                      className="search__icon search__icon--taxonomy"
                      icon="taxonomy"
                    />
                  )}
                  {post.title && parse(post.title)}
                </a>
              </li>
            ))}
          </ul>
          {currentPage < totalPages && posts.length > 0 && (
            <div className="search__loadmore loadmore">
              <button
                className="loadmore__button button button--secondary"
                type="button"
                onClick={handleLoadMore}
                disabled={isLoading}
              >
                Vis mer
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Search;
