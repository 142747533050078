import classnames from 'classnames';

import { SearchFilter } from '../../types/search';

type SearchFiltersProps = {
  className?: string;
  filters: SearchFilter[];
  activeFilter?: SearchFilter;
  onFilterClick: (newFilter: SearchFilter | null) => void;
};

function SearchFilters(props: SearchFiltersProps): JSX.Element {
  const { filters, activeFilter, onFilterClick, className } = props;

  return (
    <ul className={classnames('search-filters', className)}>
      <li className="search-filters__item">
        <button
          type="button"
          className={classnames(
            'search-filters__button',
            !activeFilter ? 'search-filters__button--active' : ''
          )}
          onClick={() => {
            if (activeFilter) {
              onFilterClick(null);
            }
          }}
        >
          Alle
        </button>
      </li>
      {filters.map((filter) => (
        <li className="search-filters__item" key={filter.id}>
          <button
            type="button"
            className={classnames(
              'search-filters__button',
              activeFilter?.id === filter.id
                ? 'search-filters__button--active'
                : ''
            )}
            onClick={() => {
              if (activeFilter?.id === filter.id) {
                return;
              }
              onFilterClick(filter);
            }}
          >
            {filter.name}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default SearchFilters;
