import { createRoot } from 'react-dom/client';

import Search from './components/search/search';

const searchApp = async () => {
  const searchContainer = document.getElementById('search');
  if (searchContainer) {
    const root = createRoot(searchContainer);
    root.render(<Search />);
  }
};

export default searchApp;
