import classnames from 'classnames';

type LoaderProps = {
  size?: 'big' | '';
};

function Loader(props: LoaderProps): JSX.Element {
  const { size = '' } = props;

  return (
    <div className={classnames('loader', size ? 'loader--big' : '')}>
      <div className="loader__main">Loading...</div>
    </div>
  );
}

export default Loader;
