export default () => {
  const SCROLLED_START = 500;
  const button = document.querySelector('.footer__scrollup');

  function buttonScrollHandler() {
    const scrollTop = window.pageYOffset;

    if (scrollTop >= SCROLLED_START) {
      button.classList.add('footer__scrollup--shown');
    } else {
      button.classList.remove('footer__scrollup--shown');
    }
  }

  if (button) {
    window.addEventListener('scroll', buttonScrollHandler, {
      passive: true,
    });
    button.addEventListener('click', () => {
      window.scrollTo(0, 0);
    });
  }
};
