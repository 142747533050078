export default () => {
  const isVisible = (elem) =>
    !!elem &&
    !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

  const header = document.querySelector('.header');
  let scrollStarted = 0;

  window.addEventListener(
    'scroll',
    () => {
      if (document.body.classList.contains('push-menu-open')) return;

      const scrollTop = window.pageYOffset;
      const delta = scrollTop - scrollStarted;

      if (delta > 0 && scrollTop > 0) {
        header.classList.add('header--hidden');
      } else {
        header.classList.remove('header--hidden');
      }

      scrollStarted = scrollTop;
    },
    { passive: true }
  );

  const hamburgerButton = document.querySelector('.header__hamburger');
  hamburgerButton.addEventListener('click', () => {
    document.body.classList.toggle('push-menu-open');
    if (document.body.classList.contains('push-menu-open')) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo({
        top: parseInt(scrollY || '0', 10) * -1,
        behavior: 'instant',
      });
    }
  });

  const topMenuItemsWithChildren = document.querySelectorAll(
    '.top-menu .menu-item-has-children'
  );
  const topMenuSubmenus = document.querySelectorAll('.top-menu .sub-menu');

  topMenuItemsWithChildren.forEach((item) => {
    item.addEventListener('click', (event) => {
      event.preventDefault();
      const target = event.currentTarget;
      target.classList.toggle('dropdown-visible');
    });
  });

  topMenuSubmenus.forEach((item) => {
    item.addEventListener('click', (event) => {
      event.stopPropagation();
    });
  });

  const outsideClickListener = (event) => {
    topMenuItemsWithChildren.forEach((item) => {
      if (!item.contains(event.target) && isVisible(item))
        item.classList.remove('dropdown-visible');
    });
  };

  document.addEventListener('click', outsideClickListener);
};
