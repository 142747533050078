type SearchFormProps = {
  searchText?: string;
};

function SearchForm(props: SearchFormProps): JSX.Element {
  const { searchText } = props;

  return (
    <form className="search__search-form search-form" role="search">
      <input
        className="search-form__input"
        type="search"
        name="s"
        aria-label="Søk"
        placeholder="Søk"
        defaultValue={searchText}
      />
      <input className="search-form__submit" type="submit" aria-label="Søk" />
    </form>
  );
}

export default SearchForm;
