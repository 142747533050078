export default () => {
  const banner = document.querySelector('.frontpage .conference-banner');
  const image = document.querySelector('.conference-banner__image svg');
  const windowHeight = window.innerHeight;

  if (banner && image) {
    window.addEventListener(
      'scroll',
      () => {
        if (window.innerWidth <= 1024) return;

        const boundary = banner.getBoundingClientRect();
        const { top, bottom, height } = boundary;
        if (top <= windowHeight && bottom >= 0) {
          const scrolledHeight = windowHeight + height;

          const imageHeight =
            100 + ((scrolledHeight - bottom) / scrolledHeight) * 60;

          image.setAttribute('style', `height: ${imageHeight}%;`);
        }
      },
      { passive: true }
    );
  }
};
