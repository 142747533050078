import { createRoot } from 'react-dom/client';

import DocumentsPage from './components/documents-page/documents-page';

const documentsApp = async () => {
  const documentsContainer = document.getElementById('documents');
  if (documentsContainer) {
    const root = createRoot(documentsContainer);
    root.render(<DocumentsPage />);
  }
};

export default documentsApp;
