import placeholder from '../../img/image-placeholder.svg';
import { fetchPosts } from '../utils/service';

const buildPostCard = (post = {}) => {
  const postCard = document.createElement('li');
  postCard.classList.add('post--small');
  const {
    link,
    title: { rendered: title = '' } = {},
    thumbnail: image = {},
  } = post;
  const {
    sizes: {
      archive: {
        url: imageUrl = '',
        width: imageWidth = '',
        height: imageHeight = '',
        sizes: imageSizes = '',
        srcset: imageSrcset = '',
      } = {},
    } = {},
  } = image || {};
  postCard.innerHTML = `
    <article class="post">
      <a class="post__link" href="${link}" aria-label="Go to ${title}">
        <div class="post__image image-container">
          ${
            image
              ? `<img width="${imageWidth}" height="${imageHeight}" src="${imageUrl}" srcset="${imageSrcset}" sizes="${imageSizes}" alt="" loading="lazy">`
              : `<img class="image-container__placeholder" src="${placeholder}" width="640" height="426" alt="Image placeholder">`
          }
        </div>
        <h3 class="post__title">${title}</h3>
      </a>
    </article>
  `;
  return postCard;
};

const renderPostCards = (listElement, posts = []) => {
  posts.forEach((item) => {
    listElement.append(buildPostCard(item));
  });
};

export default () => {
  const PER_PAGE = 9;
  let firstPageOffset;
  let postsSection;
  const archive = document.querySelector('.posts-archive');
  const archiveCategory = document.querySelector('.category__main');
  const profileSingle = document.querySelector(
    '.single-profile__posts-section'
  );

  let pageType;
  if (archive) {
    pageType = 'archive';
    firstPageOffset = 15;
    postsSection = archive;
  }
  if (archiveCategory) {
    pageType = 'category';
    firstPageOffset = 8;
    postsSection = archiveCategory;
  }
  if (document.body.classList.contains('tag')) {
    pageType = 'tag';
    firstPageOffset = 8;
    postsSection = archiveCategory;
  }
  if (profileSingle) {
    pageType = 'profile';
    firstPageOffset = 9;
    postsSection = profileSingle;
  }

  if (!postsSection) return;

  const loadMoreBtn = postsSection.querySelector('.loadmore__button');
  let currentPage = 1;
  let offset = firstPageOffset;
  if (loadMoreBtn) {
    loadMoreBtn.addEventListener('click', async (e) => {
      e.preventDefault();
      let categoryId = null;
      let tagId = null;
      let profileSlug = null;
      loadMoreBtn.disabled = true;
      if (pageType === 'category') {
        categoryId = window.__INITIAL_STATE__.term_id; /* eslint-disable-line */
      }
      if (pageType === 'tag') {
        tagId = window.__INITIAL_STATE__.term_id; /* eslint-disable-line */
      }
      if (pageType === 'profile') {
        profileSlug = window.location.pathname
          .replace('/profile/', '')
          .slice(0, -1);
      }
      const response = await fetchPosts({
        offset,
        perPage: PER_PAGE,
        categoryId,
        tagId,
        profileSlug,
      });
      renderPostCards(postsSection.querySelector('ul'), response.posts);
      loadMoreBtn.disabled = false;
      offset = firstPageOffset + currentPage * PER_PAGE;
      currentPage += 1;
      if (offset >= response.totalPosts) {
        loadMoreBtn.remove();
      }
    });
  }
};
