import './config/publicPath'; // Should be at the very beginning of entry

import domReady from '@wordpress/dom-ready';
import debounce from 'lodash.debounce';
import detectTouchSupport from './modules/detectTouchSupport';
import initHeader from './modules/initHeader';
import initCopyButton from './modules/initCopyButton';
import initScrollup from './modules/initScrollup';
import initExpandables from './modules/initExpandableBlocks';
import initSpeakersBlocks from './modules/initSpeakersBlocks';
import initParallax from './modules/initParallax';
import loadMorePosts from './modules/loadMorePosts';
import newsletter from './modules/newsletter';
import newsletterPopup from './modules/newsletterPopup';

import searchApp from './app/search-app';
import documentsApp from './app/documents-app';

const resizeHandler = debounce(() => {
  const ratio = window.innerHeight / 100;
  document.documentElement.style.setProperty('--height-point', `${ratio}px`);
}, 100);

domReady(() => {
  detectTouchSupport();
  searchApp();
  documentsApp();
  initHeader();
  initCopyButton();
  initExpandables();
  initSpeakersBlocks();
  initScrollup();
  initParallax();
  loadMorePosts();
  newsletter();
  newsletterPopup();
  resizeHandler();
  window.addEventListener('resize', resizeHandler, { passive: true });
});
